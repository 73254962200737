<template>
  <v-container
    id="contentHomeCtrl"
    :class="boxCarrousel"
    style="padding:0px!important"
  >
    <v-row>
      <v-col cols="12" style="padding-left:0px!important;padding-right:0px!important;margin-top:68px">
        <v-card elevation="0" class="mx-auto">
          <v-carousel
            :continuous="continuous"
            :cycle="cycle" 
            :show-arrows="false"
            transition="fade-transition"
            delimiter-icon="mdi-checkbox-blank-circle"
            :height="alturaCarrousel">
             <v-carousel-item
              v-for="(slide,i) in slides"
              :key="i" 
              :src="slide.src"
              reverse-transition="fade-transition"
              transition="fade-transition">
              </v-carousel-item>
          </v-carousel>
          <v-list two-line>
            <v-list-item>
              <v-list-item-content>
                <div style="text-align:center;color:#227aff;font-family: 'Arial', Times, serif;font-size:25px;font-weight:bold;word-break: break-word;line-height: 2.6rem;margin-top:5px"
                >¿Listo para comenzar?</div>
                <div class="text-center">
                  <router-link :to="{name: 'registro'}">
                    <v-btn
                      rounded
                      color="error"
                      style="width:150px;font-size:23px;margin-top:40px;text-transform: lowercase;display: inline-block;"
                      dark
                    >vamos</v-btn>
                  </router-link>
                </div>
              </v-list-item-content>
              
            </v-list-item>
          </v-list>
        </v-card>
        <!-- <v-card class flat tile style="width:599px;text-align: center;margin-top:70px">
          <div
            style="color:#227aff;font-family: 'Arial', Times, serif;font-size:36px;font-weight:bold;word-break: break-word;line-height: 2.6rem;"
          >!Bienvenido a Utax Me!</div>

          <div
            style="font-size:30px;color:black;margin-top:30px"
          >La contabilidad digital que tu AIRBNB necesita</div>
          <div
            style="color:#227aff;font-family: 'Arial', Times, serif;font-size:28px;font-weight:bold;word-break: break-word;line-height: 2.6rem;margin-top:20px"
          >¿Listo para comenzar?</div>
          <div class="text-center">
            <router-link :to="{name: 'registro'}">
              <v-btn
                rounded
                color="error"
                style="width:150px;font-size:23px;margin-top:30px;text-transform: lowercase;display: inline-block;"
                dark
              >vamos</v-btn>
            </router-link>
          </div>

          <div class="text-center">
            <router-link :to="{name: 'login'}">
              <v-btn
                text
                color="error"
                style="font-size:23px;margin-top:40px;text-transform: lowercase;"
                dark
              >o inicia sesión</v-btn>
            </router-link>
          </div>
     
        </v-card>-->
      </v-col>
      <!--       <v-col md="6" sm="0" xs="0">
        <v-img
          class="imgDiv portada white lighten-2"
          style="height:600px;margin-top:-12px;margin-rigth:-10px"
          src="https://utaxon.com/img/home1.png"
          lazy-src="https://picsum.photos/id/11/100/60"
          aspect-ratio="1"
        ></v-img>
      </v-col>-->
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ContentHome",

  data: () => ({
    boxCarrousel:'estiloPC',
    alturaCarrousel:200,
    windowWidth: 0,
    windowHeight: 0,
    colors: [
      "green",
      "secondary",
      "yellow darken-4",
      "red lighten-2",
      "orange darken-1"
    ],
    arrows: true,
    continuous: true,
    cycle: true,
    slides: [{
            src: 'https://utaxon.com/img/slider1m.png',
          },
          {
            src: 'https://utaxon.com/img/slider2m.png',
          },
          {
            src: 'https://utaxon.com/img/slider3m.png',
          },
          {
            src: 'https://utaxon.com/img/slider4m.png',
          },]
  }),
  created(){

    if(this.$route.query.email != undefined){
       console.log(this.$route.query.email);
       console.log(this.$route.query.activationCode);

        let currentObj = this;
        this.axios
        .post("https://utaxon.com/utaxon/admin/register", {
            aceptaTerminos: true,
            email: this.correo.trim(),
            nombre: this.nombre,
            password: this.contrasenia.trim(),
            telefono: this.telefono
              .trim()
              .replace("(", "")
              .replace(")", "")
              .replace(" ", "")
              .replace(" ", "")
              .replace(" ", "")
        })
        .then(function(response) {
          console.log("ok:" + response);
        })
        .catch(function(error) {
          console.log(error);
        });


    }
    
  },
  mounted() {
    this.$nextTick(function() {
      window.addEventListener("resize", this.getWindowWidth);
      window.addEventListener("resize", this.getWindowHeight);
      window.addEventListener("orientationchange", this.getOrientation);

      //Init
      this.getWindowWidth();
      this.getWindowHeight();
      this.getOrientation();

    });
    
  },
  methods: {
    getOrientation(event){
       this.getWindowWidth();
      this.getWindowHeight();
      //  if(screen.orientation.angle==0){

      //  }
    },
    getWindowWidth(event) {
      this.windowWidth = document.documentElement.clientWidth;
      //console.log(window.innerWidth);

      /* if (window.innerWidth > 648 && window.innerWidth < 1618) {
        var ancho = this.windowWidth - 600;
        // console.log(ancho);
        document.querySelector(".imgDiv").style.width = ancho + "px";
      } else if (window.innerWidth > 1618) {
        var ancho = this.windowWidth - 1000;
        // console.log(ancho);
        document.querySelector(".imgDiv").style.width = ancho + "px";
      } else {
        document.querySelector(".imgDiv").style.width = "0px";
      } */

      this.slides=[];
      if (window.innerWidth < 560) {
        console.log('mobile');
        this.alturaCarrousel= 240;
        this.slides= [{src: 'https://utaxon.com/img/slider1m.png',},
          {src: 'https://utaxon.com/img/slider2m.png',},
          {src: 'https://utaxon.com/img/slider3m.png',},
          {src: 'https://utaxon.com/img/slider4m.png',},]

          this.boxCarrousel='estiloMobile';
      } else if (window.innerWidth > 560 && window.innerWidth < 800) {
        console.log('tablet');
       this.alturaCarrousel= 360;
       this.boxCarrousel='estiloTableta';
        this.slides= [{src: 'https://utaxon.com/img/slider1t.png',},
          {src: 'https://utaxon.com/img/slider2t.png',},
          {src: 'https://utaxon.com/img/slider3t.png',},
          {src: 'https://utaxon.com/img/slider4t.png',},]
      } else if (window.innerWidth > 800 && window.innerWidth < 1024) {
        console.log('tablet');
       this.alturaCarrousel= 460;
       this.boxCarrousel='estiloTableta';
        this.slides= [{src: 'https://utaxon.com/img/slider1t.png',},
          {src: 'https://utaxon.com/img/slider2t.png',},
          {src: 'https://utaxon.com/img/slider3t.png',},
          {src: 'https://utaxon.com/img/slider4t.png',},]
      }else if (window.innerWidth > 1024 && window.innerWidth < 1200) {
        console.log('tablet');
       this.alturaCarrousel= 500;
       this.boxCarrousel='estiloTableta';
        this.slides= [{src: 'https://utaxon.com/img/slider1t.png',},
          {src: 'https://utaxon.com/img/slider2t.png',},
          {src: 'https://utaxon.com/img/slider3t.png',},
          {src: 'https://utaxon.com/img/slider4t.png',},]
      } else {
        console.log('pc');
        this.alturaCarrousel= 450;
        this.boxCarrousel='estiloPC';
         this.slides= [{src: 'https://utaxon.com/img/slider1p.png',},
          {src: 'https://utaxon.com/img/slider2p.png',},
          {src: 'https://utaxon.com/img/slider3p.png',},
          {src: 'https://utaxon.com/img/slider4p.png',},]
      }



      //this.alturaCarrousel= this.altoCarrousel;
    },

    getWindowHeight(event) {
      this.windowHeight = document.documentElement.clientHeight;
      this.$store.state.windowHeight = document.documentElement.clientHeight - 200 + "px";
      document.getElementById("contentHomeCtrl").style.height = document.documentElement.clientHeight - 220 + "px";
      //document.getElementById("chat-widget-container").style.bottom="50px";
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getWindowWidth);
    window.removeEventListener("resize", this.getWindowHeight);
  },
  computed: {
   /*  altoCarrousel(){
      console.log("alto carrousel");
       this.windowWidth = document.documentElement.clientWidth;
      //console.log(window.innerWidth);

      if (window.innerWidth < 648) {
        console.log(200);
        return 200;
      } else if (window.innerWidth > 648 && window.innerWidth < 1024) {
        console.log('648-1024');
       return 300;
      } else {
        console.log('400');
        return 400;
      } 
    } */
  },
};
</script>
<style scoped>
.estiloPC{
  width: 99%!important;
}
.estiloTableta{
  width: 99%!important;
}
.estiloMobile{
  width: 99%!important;
}
.portada {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
  width: 100%;
  text-align: center;
}
</style>