<template>
 <div>
    <content-home></content-home>
  </div>
</template>

<script>
  import ContentHome from '../components/ContentHome'

  export default {
    name: 'Home',

    components: {
      ContentHome
    },
  }
</script>